/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { Button } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';

import {
  checkAssoValidity,
  checkOdd,
  stringToSelectOption,
} from '../../../utils';

import selectStyle from './react-select-style';

import { SalaireModel } from '../../../Models/salaireCampaign.model';
import { CaisseModel } from '../../../Models/caisseCampaign.model';
import { OnlineModel } from '../../../Models/onlineCampaign.model';
import { DonationModels } from '../../../Models/donation.model';
import { oddList } from '../../../Models/odd';
import { AssoModel } from '../../../Models/asso.model';

import '../../../scss/list.scss';
import '../../../scss/asso.scss';

interface Props {
  assoList: AssoModel[];
  Salary: SalaireModel;
  Retail: CaisseModel;
  Online: OnlineModel;
}

/*
  DonationModel format to work with react-select
 */
const dispositifsOptions = [
  {
    value: DonationModels.arrondiCaisse,
    label: "L'ARRONDI en caisse",
    color: 'grey',
  },
  {
    value: DonationModels.arrondiSalaire,
    label: "L'ARRONDI sur salaire",
    color: 'grey',
  },
  {
    value: DonationModels.arrondiEnLigne,
    label: "L'ARRONDI en ligne",
    color: 'grey',
  },
];

/*
  Full List of association with filter and buffer
  Used as a page to display every association after filter
*/

/* const getFlagState = (flag: string) => {
  const url = new URLSearchParams(window.location.search).get(flag);
  if (url === 'true') {
    return true;
  }
  const result = sessionStorage.getItem(flag);
  return result === 'true';
}; */

const FullAssoList = (props: Props): JSX.Element => {
  const { assoList, Salary, Online, Retail } = props;

  const [list] = useState<AssoModel[]>(assoList)
  // Store Donation model id to filter main list
  const [donationModelFilters, setdonationModelFilters] = useState<number[]>(
    [],
  );

  // Store Odd label to filter main list
  const [oddFilters, setOddFilters] = useState<number[]>([]);

  // Offset of the list, increase on user input
  const [offset, setOffset] = useState<number>(18);

  // Realtime name filter
  const [filter, setFilter] = useState<string>('');

  // Convert react-select result to usable state
  const filterDonationModel = (selections: any) => {
    const res: number[] = [];

    selections.forEach((e: any) => {
      res.push(e.value);
    });

    setdonationModelFilters(res);
  };

  // Convert react-select result to usable state
  const filterOdd = async (selections: any) => {
    const res: number[] = [];

    selections.forEach((e: any) => {
      res.push(e.id);
    });

    setOddFilters(res);
  };

  const onClick = () => {
    setOffset(offset + 25);
  };

  // Return True if asso is part of the donationModel client list based on selected donations model; False otherwise
  const getSelectedDonationModel = (asso: AssoModel, selection: number[]) => {
    if (selection.includes(DonationModels.arrondiCaisse))
      if (Retail.linkedBenefIdGlobal.includes(asso.id, 0)) return true;
    if (selection.includes(DonationModels.arrondiSalaire))
      if (Salary.linkedBenefIdGlobal.includes(asso.id, 0)) return true;
    if (selection.includes(DonationModels.arrondiEnLigne))
      if (Online.linkedBenefIdGlobal.includes(asso.id, 0)) return true;
    return false;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  return (
    <div>
      <div className="list_top_nav_asso">
        <div className="list_filters">
          <div className="select_dispo_box">
            <span className="select_label">Dispositif</span>
            <Select
              styles={selectStyle}
              style={{ borderRadius: '35px' }}
              options={dispositifsOptions}
              isMulti
              onChange={filterDonationModel}
              placeholder="Sélectionner"
            />
          </div>
          <div className="select_odd_box">
            <span className="select_label">
              Objectif de Développement Durable{' '}
            </span>
            <Select
              styles={selectStyle}
              options={stringToSelectOption(oddList)}
              isMulti
              onChange={filterOdd}
              placeholder="Sélectionner"
            />
          </div>
          <div className="name_filter">
            <input
              value={filter}
              onChange={handleChange}
              type="text"
              style={{ border: 'none ', color: '#001e30' }}
              placeholder="Sélectionner"
            />
            <Icon
              style={{ paddingTop: '5px' }}
              path={mdiMagnify}
              size={1}
              horizontal
              vertical
              rotate={180}
            />
          </div>
        </div>
      </div>
      <div className="list_content">
        {list
          .filter((asso) => checkAssoValidity(asso))
          .filter((asso) =>
            filter === ''
              ? asso
              : asso.name
                  .toLocaleLowerCase()
                  .includes(filter.toLocaleLowerCase()),
          )
          .filter((asso) =>
            donationModelFilters.length === 0
              ? asso
              : getSelectedDonationModel(asso, donationModelFilters),
          )
          .filter((asso) =>
            oddFilters.length === 0 ? asso : checkOdd(asso.odd, oddFilters),
          )
          .slice(0, offset)
          .map((asso: AssoModel) => (
            <a href={`/asso?slug=${asso.assoSlug}`} key={asso.id}>
              <div className="asso_card">
                <img
                  className="asso_card_img"
                  src={
                    asso.logoUrl === undefined
                      ? 'https://www.chateaudesaintmartin.com/wp-content/uploads/2020/09/placeholder-960x640.png'
                      : asso.logoUrl
                  }
                  alt=""
                />
                <div data-tip={asso.name} className="asso_card_title">
                  {asso.name}
                </div>
                <ReactTooltip />
              </div>
            </a>
          ))}
      </div>
      <div style={{ width: '100%', display: 'flex', marginTop: '60px' }}>
        <Button
          variant="outlined"
          onClick={onClick}
          style={{
            width: '224px',
            height: '30px',
            borderRadius: '16px',
            border: 'solid 1px #047bc1',
            backgroundColor: '#fff',
            margin: '0 auto',
            textTransform: 'lowercase',
          }}
        >
          <span
            style={{ fontSize: '12px', textAlign: 'center', color: '#047BC1' }}
          >
            <span style={{ textTransform: 'uppercase' }}>C</span>harger plus de
            résulat
          </span>
        </Button>
      </div>
    </div>
  );
};

export default FullAssoList;
