import React from 'react';

import { AddCircle } from '@material-ui/icons';

import { checkAssoValidity } from '../../../utils';

import AssoBasicCard from '../asso_basic_card';

import { AssoModel } from '../../../Models/asso.model';

import '../../../scss/list.scss';
import '../../../scss/asso.scss';

interface Props {
  assoList: AssoModel[];
  offset: number;
}

/*
  Small list with a special card to go to main list
  Maked for Home and impact page
*/
const SmallAssoList = (props: Props): JSX.Element => {
  const { assoList, offset } = props;

  return (
    <div>
      <div className="list_content">
        {assoList
          .filter((asso) => checkAssoValidity(asso))
          .slice(0, offset)
          .map((asso: AssoModel, index) =>
            index + 1 === offset ? (
              <div className="list_see_more_block">
                <a href={`/asso?id=${asso.id}`} key={asso.id}>
                  <AssoBasicCard asso={asso} />
                </a>
                <a href="/liste-beneficiaire/">
                  <div className="list_see_more_button">
                    <span>Voir toutes les associations bénéficiaires</span>
                    <AddCircle style={{ color: '#047bc1' }} />
                  </div>
                </a>
              </div>
            ) : (
              <a href={`/asso?slug=${asso.assoSlug}`} key={asso.id}>
                <AssoBasicCard asso={asso} />
              </a>
            ),
          )}
      </div>
    </div>
  );
};

export default SmallAssoList;
