/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { fetchByIds } from '../../utils';

import AssoBasicCard from '../Modules/asso_basic_card';
import PageList from '../Modules/Lists/page_list';

import { AssoModel } from '../../Models/asso.model';
import { EntrepriseModel } from '../../Models/entreprise.model';
import { OnlineModel } from '../../Models/onlineCampaign.model';

import Mascote from '../../assets/mascotte_larrondi_dark.png';

interface Props {
  slug: string | null;
  assoList: AssoModel[];
  Online: OnlineModel;
  entrepriseList: EntrepriseModel[];
}

export const OnlinePageDescCrosscall = (props: Props): JSX.Element => {
  const { entrepriseList, slug } = props;

  const entreprise = entrepriseList.find((e) => e.entrepriseSlug === slug);
  if (entreprise === undefined) return <div>Entreprise Error</div>;

  return (
    <div className="page_desc_and_cgu">
      <span>{entreprise.desc}</span>
      <div className="page_cgu">
        Vous avez fait un don en ligne ?
        <div className="page_cgu_button">
          <a
            href="https://larrondi.org/wp-content/uploads/tmp_pdf/crosscallcgu.pdf"
            style={{
              color: '#047bc1',
              fontSize: '16px',
            }}
          >
            Consulter les CGU
          </a>
        </div>
      </div>
    </div>
  );
};

/*
  Page Desc Module for online donation client
*/
export const OnlinePageDesc = (props: Props): JSX.Element => {
  const { entrepriseList, slug } = props;

  const entreprise = entrepriseList.find((e) => e.entrepriseSlug === slug);
  if (entreprise === undefined) return <div>Entreprise Error</div>;

  if (entreprise.id === '637757c412211f0021525ab9')
    return <OnlinePageDescCrosscall {...props} />;

  return (
    <div className="page_desc">
      <span>{entreprise.desc}</span>
    </div>
  );
};

/*
  Page Title Module for online donation client
*/
export const OnlinePageTitle = (props: Props): JSX.Element => {
  const { slug, entrepriseList } = props;

  if (slug === null) return <div>Name Error</div>;

  const entreprise = entrepriseList.find((e) => e.entrepriseSlug === slug);
  if (entreprise === undefined) return <div>Entreprise Error</div>;

  return (
    <div className="page_title">
      <div className="page_title_left">
        <div className="page_title_left_img">
          <img
            className="page_title_img"
            src={
              entreprise.logoUrl === undefined
                ? 'https://www.chateaudesaintmartin.com/wp-content/uploads/2020/09/placeholder-960x640.png'
                : entreprise.logoUrl
            }
            alt={entreprise.name}
          />
        </div>
        <div className="page_title_left_text">
          <p className="page_title_left_text_donationType">
            L&lsquo;ARRONDI en ligne
          </p>
          <p className="page_title_left_text_name">{entreprise.name}</p>
        </div>
      </div>
      <div className="page_title_right">
        <img src={Mascote} alt="mascotte arrondi" height="300" />
      </div>
    </div>
  );
};

/*
  Page Content Module for online donation client
*/
const OnlinePage = (props: Props): JSX.Element => {
  const { slug, assoList, Online, entrepriseList } = props;

  if (slug === null) return <div>Name Error</div>;

  const entreprise = entrepriseList.find((e) => e.entrepriseSlug === slug);
  const campaign = Online.list.find((e) => e.entrepriseSlug === slug);

  if (entreprise === undefined) return <div>Entreprise Error</div>;
  if (campaign === undefined) return <div>Campaign Error</div>;

  const linkedAsso: AssoModel[] = fetchByIds(
    assoList,
    campaign.linkedCurrentBenef,
  );
  const linkedAssoOver: AssoModel[] = fetchByIds(
    assoList,
    campaign.linkedBenefIdOver,
  );

  return (
    <div>
      {linkedAsso.length > 0 && (
        <PageList
          title="Associations soutenues"
          content={
            <div className="page_list_content">
              {linkedAsso.map((asso: AssoModel) => (
                <a href={`/asso?slug=${asso.assoSlug}`} key={asso.id}>
                  <AssoBasicCard asso={asso} />
                </a>
              ))}
            </div>
          }
        />
      )}
      {linkedAssoOver.length > 0 && (
        <PageList
          title="Associations soutenues précédemment"
          content={
            <div className="page_list_content">
              {linkedAssoOver.map((asso: AssoModel) => (
                <a href={`/asso?slug=${asso.assoSlug}`} key={asso.id}>
                  <AssoBasicCard asso={asso} />
                </a>
              ))}
            </div>
          }
        />
      )}
    </div>
  );
};

export default OnlinePage;
