import React from 'react';
import Icon from '@mdi/react';

import '../../scss/metrics.scss';

interface MetricCardModel {
  icon: string;
  title: string;
  subtitle: string;
  color: string;
  textColor: string;
}

interface Props {
  title: string;
  subtitle: string;
  textColor: string;
}

interface BiColorProps {
  title: string;
  subtitle: string;
  textColor: string;
  subColor: string;
}

/*
  Collection of metric card used to display a title and a value in all KeyNbr bar
*/
const MetricCard = (props: MetricCardModel): JSX.Element => {
  const { icon, title, subtitle, color, textColor } = props;

  return (
    <div className="metric_card" style={{ color: textColor }}>
      <Icon
        path={icon}
        size={2}
        horizontal
        vertical
        color={color}
        rotate={180}
      />
      <div className="metric_card_text">
        <span className="metric_card_title" style={{ color: textColor }}>
          {title}
        </span>
        <span className="metric_card_subtitle" style={{ color: textColor }}>
          {subtitle}
        </span>
      </div>
    </div>
  );
};

export const MetricCardLeftAlign = (props: MetricCardModel): JSX.Element => {
  const { icon, title, subtitle, color, textColor } = props;

  return (
    <div className="metric_card" style={{ color: textColor }}>
      <Icon
        path={icon}
        size={2}
        horizontal
        vertical
        color={color}
        rotate={180}
      />
      <div className="metric_card_text_la">
        <span className="metric_card_title">{title}</span>
        <span className="metric_card_subtitle">{subtitle}</span>
      </div>
    </div>
  );
};

export const MetricCardNoIcon = (props: Props): JSX.Element => {
  const { title, subtitle, textColor } = props;

  return (
    <div className="metric_card" style={{ color: textColor }}>
      <div className="metric_card_text">
        <span className="metric_card_title" style={{ color: textColor }}>
          {title}
        </span>
        <span className="metric_card_subtitle" style={{ color: textColor }}>
          {subtitle}
        </span>
      </div>
    </div>
  );
};

export const MetricCardNoIconBiColor = (props: BiColorProps): JSX.Element => {
  const { title, subtitle, textColor, subColor } = props;

  return (
    <div className="metric_card">
      <div className="metric_card_text">
        <span className="metric_card_title" style={{ color: textColor }}>
          {title}
        </span>
        <span className="metric_card_subtitle" style={{ color: subColor }}>
          {subtitle}
        </span>
      </div>
    </div>
  );
};

export default MetricCard;
