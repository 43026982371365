/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import L, { LatLngExpression } from 'leaflet';
import React, { useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvent } from 'react-leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import Icon from '@mdi/react';
import { mdiMapMarker } from '@mdi/js';

import { checkColectorValidity, fetchByIds } from '../../utils';

import {
  AdressModel,
  CaisseCampaignModel,
  ColectorModel,
} from '../../Models/caisseCampaign.model';
import { AssoModel } from '../../Models/asso.model';

import '../../scss/map.scss';
import MapRef from './map/mapRef';

interface Props {
  retailCampaign: CaisseCampaignModel;
  assoList: AssoModel[];
}

interface SideData {
  name: string;
  adress: AdressModel;
  benefs: AssoModel[];
}

function SetViewOnClick({ animateRef }: any) {
  const map = useMapEvent('click', (e) => {
    map.setView(e.latlng, map.getZoom(), {
      animate: animateRef.current || false,
    });
  });
  return null;
}

/*
  Interactive Map that display every collector of an association
*/
const InteractiveMap = (props: Props): JSX.Element => {
  const { retailCampaign, assoList } = props;

  if (retailCampaign.collectorsWithOngoingCampaign.length === 0)
    return <div>Magasin Error</div>;

  if (retailCampaign.ongoingCampaigns.length === 0)
    return <div>Campaign Error</div>;


  console.log('current Campagne', retailCampaign);
  /*
    State for all data relative to last selected Collector (default: Arr[0])
    Hold: 
      - Name of the collector
      - Adress of the collector
      - All beneficiaries of the colletor
  */

  const [sideData, setSideData] = useState<SideData>({
    name: retailCampaign.collectorsWithOngoingCampaign[0].name,
    adress: retailCampaign.collectorsWithOngoingCampaign[0].address,
    benefs: fetchByIds(
      assoList,
      retailCampaign.collectorsWithOngoingCampaign[0].beneficiariesIds,
    ) as AssoModel[],
  });

  // Icon overwrite for leaflet
  const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [24, 36],
    iconAnchor: [12, 36],
  });
  L.Marker.prototype.options.icon = DefaultIcon;

  const animateRef = useRef(false);

  // Hold latitude in France bound to avoid worlwide bound
  const normalizeLat = (lat: number) => {
    if (lat > 50.10488684130479) return 50.10488684130479; // Max France latitude
    if (lat < 40.475972657655596) return 40.475972657655596; // Min France latitude
    return lat;
  };

  // Hold longitude in France bound to avoid worlwide bound
  const normalizeLong = (long: number) => {
    if (long < -4.327035437200568) return -4.327035437200568; // Min France longitude
    if (long > 9.025898204580472) return 9.025898204580472; // Max France longitude
    return long;
  };

  // Get all coordinates for collectors and interpol their positions to create customs bounds
  const getLatLngBounds = () => {
    const positions = [] as any;

    retailCampaign.collectorsWithOngoingCampaign
      .filter((e) => checkColectorValidity(e))
      .forEach((magasin: any) => {
        positions.push([
          +magasin.coordinates[0].replace(',', '.'),
          +magasin.coordinates[1].replace(',', '.'),
        ]);
      });

    const latLngs = positions.map((position: any) => {
      return L.latLng(normalizeLat(position[0]), normalizeLong(position[1]));
    });
    const bounds = L.latLngBounds(latLngs);
    return bounds;
  };

  const getMinDate = () => {
    const container: any = [];

    retailCampaign.ongoingCampaigns.forEach((e) => {
      container.push(new Date(e.startDate));
    });

    return new Date(Math.min.apply(null, container)).toLocaleDateString();
  };

  const getMaxDate = () => {
    const container: any = [];

    retailCampaign.ongoingCampaigns.forEach((e) => {
      container.push(new Date(e.endDate));
    });

    return new Date(Math.max.apply(null, container)).toLocaleDateString();
  };

  // Coordinates conversion to leaflet format
  const getLngLat = (src: string[]) => {
    return [
      +src[0].replace(',', '.'), // Replace , by . for the value to be treated as double
      +src[1].replace(',', '.'),
    ] as LatLngExpression;
  };

  const [selected, setSelected] = React.useState<any>([]);

  const handleMarkerClick = (colector: ColectorModel) => {
    setSelected([colector.id]);
    setSideData({
      name: colector.name,
      adress: colector.address,
      benefs: fetchByIds(assoList, colector.beneficiariesIds) as AssoModel[],
    });
  };

  return (
    <div id="map_box">
      <div>
        <MapContainer
          className="map"
          bounds={getLatLngBounds()}
          zoom={4.5}
          scrollWheelZoom={false}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <MapRef
            onMarkerClick={handleMarkerClick}
            selected={selected}
            colectors={retailCampaign.collectorsWithOngoingCampaign}
          />
        </MapContainer>
      </div>
      <div className="map_sidebox">
        <p className="map_sidebox_date">
          Du {getMinDate()} au {getMaxDate()}
        </p>
        <p className="map_sidebox_title">
          Trouvez un magasin près de chez vous !
        </p>
        <div className="map_sidebox_colector_w_icon">
          <Icon
            path={mdiMapMarker}
            size={3}
            horizontal
            vertical
            color="white"
            rotate={180}
          />
          <div className="map_sidebox_colector">
            <p className="map_sidebox_colector_name">{sideData.name}</p>
            <div className="map_sidebox_adress_block">
              {sideData.adress.street && (
                <p className="map_sidebox_colector_adress">
                  {sideData.adress.street}
                </p>
              )}

              {sideData.adress.street2 && (
                <p className="map_sidebox_colector_adress">
                  {sideData.adress.street2}
                </p>
              )}

              {sideData.adress.zip && sideData.adress.city && (
                <p className="map_sidebox_colector_adress">
                  {sideData.adress.zip} {sideData.adress.city}
                </p>
              )}
            </div>
            <p className="map_sidebox_colector_benef">
              pour{' '}
              {sideData.benefs.map((benef) => (
                <span key={benef.id} className="benef_name">
                  {' '}
                  {benef.name},
                </span>
              ))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteractiveMap;
