import React from 'react';

import { EntrepriseModel } from '../../Models/entreprise.model';
import { DonationModels } from '../../Models/donation.model';

import '../../scss/entreprise_card.scss';

import bag from '../../assets/bag.png';
import building from '../../assets/building.png';
import shop from '../../assets/shop.png';

interface Props {
  entreprise: EntrepriseModel;
  donationModel: DonationModels;
}

/*
  Entreprise Card used on all entreprise list
  The card will have a different behavior and design based on the Donation Model ID
*/
const EntrepriseCard = (props: Props): JSX.Element => {
  const { entreprise, donationModel } = props;
  let backgroundColorDm = '#44c8f5';
  let textColor = '#001e30';
  let imgSrc = '';
  let text = '';
  let href = ``;

  if (donationModel === DonationModels.arrondiSalaire) {
    backgroundColorDm = '#282a55';
    textColor = '#ffffff';
    imgSrc = building;
    text = 'Sur salaire';
    href = `/salaire?slug=${entreprise.entrepriseSlug}`;
  } else if (donationModel === DonationModels.arrondiCaisse) {
    backgroundColorDm = '#44c8f5';
    textColor = '#001e30';
    imgSrc = shop;
    text = 'En caisse';
    href = `/retail?slug=${entreprise.entrepriseSlug}`;
  } else if (donationModel === DonationModels.arrondiEnLigne) {
    backgroundColorDm = '#ffe72d';
    textColor = '#001e30';
    imgSrc = bag;
    text = 'En ligne';
    href = `/online?slug=${entreprise.entrepriseSlug}`;
  }
  return (
    <div id="entreprise_card_box">
      <a href={href}>
        <div id="entreprise_card_img_box">
          <img
            src={entreprise.logoUrl}
            alt={entreprise.name}
            id="entreprise_card_img"
          />
        </div>
        <img src={imgSrc} alt="" id="entreprise_card_dm_icon" />
        <div id="entreprise_card_top" />
        <div id="entreprise_card_bottom">
          <div
            id="entreprise_card_dm_box"
            style={{ backgroundColor: backgroundColorDm }}
          >
            <p id="entreprise_card_dm" style={{ color: textColor }}>
              {text}
            </p>
          </div>
          <p id="entreprise_card_top_text">L&lsquo;ARRONDI chez</p>
          <p id="entreprise_card_bottom_text">{entreprise.name}</p>
        </div>
      </a>
    </div>
  );
};

export default EntrepriseCard;
