/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, BottomNavigationAction, Button, TextField, Typography, BottomNavigation, Box } from '@material-ui/core';

import '../../scss/json.scss';
import { ArrowDownward, CheckCircle, Delete, DoneAllSharp } from '@material-ui/icons';
import ReactTooltip from 'react-tooltip';
import { EntrepriseModel } from '../../Models/entreprise.model';
import DataModel from '../../Models/data.model';
import GeneralModel from '../../Models/general.model';
import { AssoModel } from '../../Models/asso.model';
import { oddList } from '../../Models/odd';
import { SalaireCampaignModel, SalaireModel } from '../../Models/salaireCampaign.model';
import { CaisseCampaignModel, CampaignModel } from '../../Models/caisseCampaign.model';
import { checkColectorValidity } from '../../utils';
import { OnlineCampaignModel, OnlineModel } from '../../Models/onlineCampaign.model';


interface GeneralProps {
    data: GeneralModel;
}

interface AssosProps {
    data: AssoModel[];
}

interface EntrepriseProps {
    data: EntrepriseModel[];
}

interface SalaireProps {
    data: SalaireCampaignModel[];
    assos: AssoModel[];
}

interface RetailProps {
    data: CaisseCampaignModel[];
    assos: AssoModel[];
}

interface OnlineProps {
    data: OnlineCampaignModel[];
    assos: AssoModel[];
}

const GeneralDisplay = (props: GeneralProps) => {
    const {data} = props;

    return (
        <div>
            <p>Total des dons : <strong>{data.totalDons.nombreDon}</strong> depuis : <strong>{data.totalDons.since}</strong> pour un total de <strong>{data.totalDons.value}</strong> €</p>
            <p>Total des beneficiaires : <strong>{data.totalBeneficiaries}</strong> </p>
        </div>
    );
}

const AssosDisplay = (props: AssosProps) => {
    const {data} = props;

    const [searchedLabel, setSearchedLabel] = useState<string>('');

    return (
        <div className='display'>
            <p>La recherche peut prendre un peu de temps merci de patienter</p>
            <TextField placeholder='Asso...' onChange={(event: any) => {setSearchedLabel(event.target.value)}} />
            {
                data.filter((e) =>
                searchedLabel === ''
                    ? false
                    : e.name
                        .toLocaleLowerCase()
                        .includes(searchedLabel.toLocaleLowerCase()),
                ).map((e: AssoModel) => {
                    return (
                        <div key={e.id}>
                            <Accordion style={{width: '600px'}}>
                                <AccordionSummary
                                expandIcon={<ArrowDownward />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>{e.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    <div>
                                        <img src={e.logoUrl} className="logo" alt='logo'/>
                                        <p><strong className='title'>slug</strong>: {e.assoSlug}</p>
                                        <p><strong className='title'>id</strong>: {e.id}</p>
                                        <p><strong className='title'>url site</strong> : <a href={e.websiteUrl}>{e.websiteUrl}</a></p>
                                        <p><strong className='title'>Liste des odd</strong></p>
                                        {
                                            e.odd?.map((odd: number) => {
                                                return (
                                                    <img
                                                        key={odd}
                                                        className="odd"
                                                        src={oddList.find((el) => el.id === odd)?.visual}
                                                        alt={oddList.find((el) => el.id === odd)?.name}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </Typography>
                                </AccordionDetails>
                            </Accordion> 
                        </div>
                    )
                })
            }
        </div>
    );
}

const EntrepriseDisplay = (props: EntrepriseProps) => {
    const {data} = props;

    const [searchedLabel, setSearchedLabel] = useState<string>('');

    return (
        <div className='display'>
            <p>La recherche peut prendre un peu de temps merci de patienter</p>
            <TextField placeholder='Asso...' onChange={(event: any) => {setSearchedLabel(event.target.value)}} />
            {
                data.filter((e) =>
                searchedLabel === ''
                    ? false
                    : e.name
                        .toLocaleLowerCase()
                        .includes(searchedLabel.toLocaleLowerCase()),
                ).map((e: EntrepriseModel) => {
                    return (
                        <div key={e.id}>
                            <Accordion style={{width: '600px'}}>
                                <AccordionSummary
                                expandIcon={<ArrowDownward />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>{e.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    <div>
                                        <img src={e.logoUrl} className="logo" alt='logo'/>
                                        <p><strong className='title'>slug</strong>: {e.entrepriseSlug}</p>
                                        <p><strong className='title'>description</strong> : {e.desc}</p>
                                        <p><strong className='title'>id</strong>: {e.id}</p>
                                        
                                    </div>
                                </Typography>
                                </AccordionDetails>
                            </Accordion> 
                        </div>
                    )
                })
            }
        </div>
    );
}

const OnlineDisplay = (props: OnlineProps) => {
    const {data, assos} = props;

    const [searchedLabel, setSearchedLabel] = useState<string>('');

    return (
        <div className='display'>
            <p>La recherche peut prendre un peu de temps merci de patienter</p>
            <TextField placeholder='Entreprise slug...' onChange={(event: any) => {setSearchedLabel(event.target.value)}} />
            {
                data.filter((e) =>
                searchedLabel === ''
                    ? false
                    : e.entrepriseSlug
                        .toLocaleLowerCase()
                        .includes(searchedLabel.toLocaleLowerCase()),
                ).map((e: OnlineCampaignModel) => {
                    return (
                        <div key={e.entrepriseId}>
                            <Accordion style={{width: '600px'}}>
                                <AccordionSummary
                                expandIcon={<ArrowDownward />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>{e.entrepriseSlug}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    <div>
                                        <p><strong className='title'>Assos précedement soutenues</strong></p>
                                        {
                                            e.linkedBenefIdOver?.map((id: string) => {
                                                return <p key={id}>{assos.find((el) => el.id === id)?.name}</p>
                                            })
                                        }
                                        <p><strong className='title'>Assos actuellement soutenues</strong></p>
                                        {
                                            e.linkedCurrentBenef?.map((id: string) => {
                                                return <p key={id}>{assos.find((el) => el.id === id)?.name}</p>
                                            })
                                        }
                                    </div>
                                </Typography>
                                </AccordionDetails>
                            </Accordion> 
                        </div>
                    )
                })
            }
        </div>
    );
}

const SalaireDisplay = (props: SalaireProps) => {
    const {data, assos} = props;

    const [searchedLabel, setSearchedLabel] = useState<string>('');

    return (
        <div className='display'>
            <p>La recherche peut prendre un peu de temps merci de patienter</p>
            <TextField placeholder='Entreprise slug...' onChange={(event: any) => {setSearchedLabel(event.target.value)}} />
            {
                data.filter((e) =>
                searchedLabel === ''
                    ? false
                    : e.entrepriseSlug
                        .toLocaleLowerCase()
                        .includes(searchedLabel.toLocaleLowerCase()),
                ).map((e: SalaireCampaignModel) => {
                    return (
                        <div key={e.entrepriseId}>
                            <Accordion style={{width: '600px'}}>
                                <AccordionSummary
                                expandIcon={<ArrowDownward />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>{e.entrepriseSlug}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    <div>
                                        <p><strong className='title'>Assos précedement soutenues</strong></p>
                                        {
                                            e.linkedBenefIdOver?.map((id: string) => {
                                                return <p key={id}>{assos.find((el) => el.id === id)?.name}</p>
                                            })
                                        }
                                        <p><strong className='title'>Assos actuellement soutenues</strong></p>
                                        {
                                            e.linkedCurrentBenef?.map((id: string) => {
                                                return <p key={id}>{assos.find((el) => el.id === id)?.name}</p>
                                            })
                                        }
                                    </div>
                                </Typography>
                                </AccordionDetails>
                            </Accordion> 
                        </div>
                    )
                })
            }
        </div>
    );
}

const RetailDisplay = (props: RetailProps) => {
    const {data, assos} = props;

    const [searchedLabel, setSearchedLabel] = useState<string>('');

    return (
        <div className='display'>
            <p>La recherche peut prendre un peu de temps merci de patienter</p>
            <TextField placeholder='Entreprise slug...' onChange={(event: any) => {setSearchedLabel(event.target.value)}} />
            {
                data.filter((e) =>
                searchedLabel === ''
                    ? false
                    : e.entrepriseSlug
                        .toLocaleLowerCase()
                        .includes(searchedLabel.toLocaleLowerCase()),
                ).map((e: CaisseCampaignModel) => {
                    return (
                        <div key={e.entrepriseId}>
                            <Accordion style={{width: '600px'}}>
                                <AccordionSummary
                                expandIcon={<ArrowDownward />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>{e.entrepriseSlug}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    <div className='accordion-content'>
                                        <p><strong className='title'>CGU</strong> : <a href={e.urlCgu}>voir</a></p>
                                        <p><strong className='title'>Reçu fiscaux activés</strong> : {e.urlRecuFiscal}</p>
                                        <p><strong className='title'>Assos précedement soutenues</strong></p>
                                        {
                                            e.linkedBenefOver?.map((id: string) => {
                                                return <p key={id}>{assos.find((el) => el.id === id)?.name}</p>
                                            })
                                        }
                                        <p><strong className='title'>Assos actuellement soutenues</strong></p>
                                        {
                                            e.linkedCurrentBenef?.map((id: string) => {
                                                return <p key={id}>{assos.find((el) => el.id === id)?.name}</p>
                                            })
                                        }
                                        <p><strong className='title'>Nombre de collecteurs valide</strong> : {e.collectorsWithOngoingCampaign.filter((el) => checkColectorValidity(el)).length} </p>
                                        <p><strong className='title'>Campgnes</strong> : </p>
                                        {
                                            e.ongoingCampaigns?.map((campaign: CampaignModel) => {
                                                return (
                                                    <div key={campaign.id} style={{borderBottom: '1px solid black'}}>
                                                        <p><strong className='subtitle'>dates </strong>: {campaign.startDate} - {campaign.endDate}</p>
                                                        <p><strong className='subtitle'>total beneficiaires</strong> : {campaign.totalBeneficiaries}</p>
                                                        <p><strong className='subtitle'>total don</strong> {campaign.totalDons?.value}</p>
                                                        <p><strong className='subtitle'>id</strong> : {campaign.id}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Typography>
                                </AccordionDetails>
                            </Accordion> 
                        </div>
                    )
                })
            }
        </div>
    );
}



interface Props {
    files: string[];
    lastUpdated: string;
}
const JsonUpdate = (props: Props) : JSX.Element => {
    const { files, lastUpdated } = props;

    const [data, setData] = useState<DataModel | null>(null);

    const [deletedFile, setDeletedFile] = useState<string>('');

    const [revertedFile, setRevertedFile] = useState<string>('');

    const [dataDate, setDataDate] = useState<string>('');


    const [filter, setFilter] = useState<string>('general');

    function getFullDate(timestamp: number) {
        const date = new Date(timestamp);
        return (date.toLocaleString('fr-FR'));
    }

    async function loadData(timestamp: string) {
        const res = await fetch(
            `/wp-content/uploads/tdl_data/tdl_${timestamp}.json`
        );
        const json = await res.json();
        console.log('json: ', json);
        setData(json);
        setDataDate(getFullDate(+timestamp));
    }

    function getDataInfo() {
        switch (filter) {
            case 'general':
                return <GeneralDisplay data={data!.general}/>;
            case 'assos':
                return <AssosDisplay data={data!.asso.list} />;
            case 'entreprises':
                return <EntrepriseDisplay data={data!.entreprises.list} />;
            case 'salaire':
                return <SalaireDisplay data={data!.DonationModels.Salaire.list} assos={data!.asso.list}/>;
            case 'retail':
                return <RetailDisplay data={data!.DonationModels.Caisse.list} assos={data!.asso.list} />;
            case 'online':
                return <OnlineDisplay data={data!.DonationModels.EnLigne.list} assos={data!.asso.list} />;
            default:
                return <p>WIP</p>
        }
    }

    return (
        <div>
            <div className='main'>
                <div className='current'>
                    <div className='elem'>
                        <CheckCircle htmlColor='#2D913C' />
                        <p style={{fontSize: '20px'}}>Version actuelle : {getFullDate(+lastUpdated)}</p>
                    </div>
                    <div className='elem'>
                        <Button variant='contained' color='primary' onClick={() => loadData(lastUpdated)} style={{backgroundColor: '#04bc1', height: '50px'}}>Voir Details</Button>
                        <form method="post" action="">
                            <Button type='submit' color='primary' name="mysubmitbtn" variant='contained' style={{backgroundColor: '#04bc1', height: '50px'}} >
                                Mettre à jour les données
                            </Button>
                        </form>
                    </div>
                </div>
                <h4>Data-viewer</h4>
                <div className='data-viewer'>
                    {
                        data === null && <p>No data loaded</p>
                    }
                    {
                        data !== null &&
                        <Box>
                            <h1 style={{fontSize: '23px'}}>Données du {dataDate}</h1>
                            <div className='buttons'>
                                <Button variant='contained' onClick={() => setFilter('general')}>Général</Button>
                                <Button variant='contained' onClick={() => setFilter('assos')}>Assos</Button>
                                <Button variant='contained' onClick={() => setFilter('entreprises')}>Entreprises</Button>
                                <Button variant='contained' onClick={() => setFilter('salaire')}>Campagnes Salaire</Button>
                                <Button variant='contained' onClick={() => setFilter('retail')}>Campagnes Retail</Button>
                                <Button variant='contained' onClick={() => setFilter('online')}>Campagnes en ligne</Button>
                            </div>
                            <div className='data'>
                                {getDataInfo()}
                            </div>
                            
                        </Box>
                        
                    }
                </div>
            </div>
        </div>
    );
}



export default JsonUpdate;