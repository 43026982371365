/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactDOM from 'react-dom';
import shuffle from 'lodash.shuffle';

import GeneralModel from './Models/general.model';
import { AssoModel } from './Models/asso.model';
import {
  EntrepriseModel,
  EntrepriseRelational,
} from './Models/entreprise.model';
import ImpactPageOddGraph, {
  ImpactPageAssoList,
  ImpactPageKeyNbr,
} from './Components/Pages/impact';
import FullAssoList from './Components/Modules/Lists/asso_full';
import { SalaireModel } from './Models/salaireCampaign.model';
import { CaisseModel } from './Models/caisseCampaign.model';
import { OnlineModel } from './Models/onlineCampaign.model';
import EntrepriseCard from './Components/Modules/entrepriseCard';
import SmallEntrepriseList from './Components/Modules/Lists/entreprise_small';
import HomePageKeyNbr, { HomePageAssoList } from './Components/Pages/home';
import FullEntrepriseList from './Components/Modules/Lists/entreprise_full';
import {
  customAssoRules,
  getShuffledDmArray,
} from './utils';
import AssoPage, { AssoPageDesc, AssoPageTitle } from './Components/Pages/asso';
import RetailPage, {
  RetailPageDesc,
  RetailPageTitle,
} from './Components/Pages/retail';
import SalaryPage, {
  SalaryPageDesc,
  SalaryPageTitle,
} from './Components/Pages/salaire';
import OnlinePage, {
  OnlinePageDesc,
  OnlinePageTitle,
} from './Components/Pages/online';

import 'leaflet/dist/leaflet.css';
import JsonUpdate from './Components/Pages/update';

declare global {
  interface Window {
    erwSettings: any;
  }
}

interface InitialProps {
  textColor: string;
  color: string;
  componentName: string;
  data: any;
  files: string;
  lastUpdated: string;
}

interface Props {
  slug: string | null;
  general: GeneralModel;
  assoList: AssoModel[];
  entrepriseList: EntrepriseModel[];
  shuffledDmList: EntrepriseRelational[];
  Salary: SalaireModel;
  Retail: CaisseModel;
  Online: OnlineModel;
  color: string;
  textColor: string;
  files: string[];
  lastUpdated: string;
}

const ModelTest = () => {
  return (
    <div>
      Module Error
    </div>
  );
};

// List of all component that can be used on wordpress
const components = [
  { name: 'impact_page_keynbr', component: ImpactPageKeyNbr },
  { name: 'impact_page_asso_list', component: ImpactPageAssoList },
  { name: 'impact_page_odd_graph', component: ImpactPageOddGraph },
  { name: 'asso_list_full', component: FullAssoList },
  { name: 'home_entreprise_list', component: SmallEntrepriseList },
  { name: 'home_asso_list', component: HomePageAssoList },
  { name: 'home_keynbr', component: HomePageKeyNbr },
  { name: 'entreprise_list_full', component: FullEntrepriseList },
  { name: 'asso_page_title', component: AssoPageTitle },
  { name: 'asso_page_desc', component: AssoPageDesc },
  { name: 'asso_page', component: AssoPage },
  { name: 'retail_page_title', component: RetailPageTitle },
  { name: 'retail_page_desc', component: RetailPageDesc },
  { name: 'retail_page', component: RetailPage },
  { name: 'salary_page_title', component: SalaryPageTitle },
  { name: 'salary_page_desc', component: SalaryPageDesc },
  { name: 'salary_page', component: SalaryPage },
  { name: 'online_page_title', component: OnlinePageTitle },
  { name: 'online_page_desc', component: OnlinePageDesc },
  { name: 'online_page', component: OnlinePage },
  { name: 'form', component: JsonUpdate}
];

const Dispatcher = (props: InitialProps) => {
  const { componentName, data, color, textColor, files, lastUpdated } = props;

  // Create a full arg object from the JSON data
  const args: Props = {
    slug: new URLSearchParams(window.location.search).get('slug'),
    general: data.general,
    assoList: customAssoRules(shuffle(data.asso?.list)) || [],
    entrepriseList: data.entreprises?.list || [],
    Salary: data.DonationModels?.Salaire || [],
    Retail: data.DonationModels?.Caisse || [],
    Online: data.DonationModels?.EnLigne || [],
    shuffledDmList: [],
    color,
    textColor,
    files: files.split(';'),
    lastUpdated
  };

  args.shuffledDmList = getShuffledDmArray(
    args.Salary,
    args.Retail,
    args.Online,
    args.entrepriseList,
  );

  return (
    components.find((el) => el.name === componentName)?.component(args) || (
      <ModelTest />
    )
  );
};

// Check from the wordpress DB if the last update of JSON is posterior to current date and remove localeSotrage if so
const getLastUpdate = async () => {
  const myHeaders = new Headers();
  myHeaders.append('Cache-Control', 'max-age=0');

  const myInit: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
  };
  const res = await fetch(
    '/wp-content/uploads/tdl_data/tdl_last_updated',
    myInit,
  );
  const lastUpdate = await res.text();
  const localLastUpdate = localStorage.getItem('tdlDataLastUpdate');
  // console.log(`local:${localLastUpdate}|| Last Upddate: ${lastUpdate}`);
  if (localLastUpdate === null || +localLastUpdate < +lastUpdate) {
    // console.log('old json detected');
    localStorage.removeItem('tdlData');
  }
  return lastUpdate;
};

// Fetch tdl.json if localeStorage is empty (check getLastUpdate())
const getData = async () => {
  const lastUpdate = await getLastUpdate();
  let json = localStorage.getItem('tdlData');
  if (json == null || json === '') {
    console.log('fetch data');
    const res = await fetch(
      `/wp-content/uploads/tdl_data/tdl_${lastUpdate}.json`
    );
    json = await res.json();
    console.log('got ', json);
    if (json == null) return {};
    localStorage.setItem('tdlData', JSON.stringify(json));
    localStorage.setItem('tdlDataLastUpdate', Date.now().toString());
    return json;
  }
  return JSON.parse(json);
};

const targets = document.querySelectorAll('.erw-root');
Array.prototype.forEach.call(targets, async (target) => {
  const { id } = target.dataset;
  const settings = window.erwSettings[id];
  const data = await getData();
  ReactDOM.render(
    <Dispatcher
      componentName={settings.component_name}
      data={data}
      color={settings.color}
      textColor={settings.text_color}
      files={settings.files}
      lastUpdated={settings.lastUpdated}
    />,
    target,
  );
});
