import * as React from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';
import MarkerContainer from './markerContainer';

interface Props {
  onMarkerClick: any;
  selected: any[];
  mapRef: any;
  markers: any;
}

function MarkerClusterContainer({
  mapRef,
  markers,
  onMarkerClick,
  selected,
}: Props) {
  React.useEffect(() => {
    if (mapRef) {
      mapRef.invalidateSize();
    }
  }, [mapRef]);

  return (
    <React.Fragment>
      <MarkerClusterGroup>
        {markers.map((marker: any) => {
          const selectedM = selected?.some(
            (building) => building === marker?.colector.id,
          );
          return (
            <MarkerContainer
              position={marker.pos}
              key={marker.colector.id}
              colector={marker.colector}
              onMarkerClick={onMarkerClick}
              selected={selectedM}
            />
          );
        })}
      </MarkerClusterGroup>
    </React.Fragment>
  );
}

export default MarkerClusterContainer;
