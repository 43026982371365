/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import ReactTooltip from 'react-tooltip';

import { getDmByAsso } from '../../utils';

import EntrepriseCard from '../Modules/entrepriseCard';
import PageList from '../Modules/Lists/page_list';

import { AssoModel } from '../../Models/asso.model';
import {
  EntrepriseModel,
  EntrepriseRelational,
} from '../../Models/entreprise.model';
import { SalaireModel } from '../../Models/salaireCampaign.model';
import { CaisseModel } from '../../Models/caisseCampaign.model';
import { OnlineModel } from '../../Models/onlineCampaign.model';
import { oddList } from '../../Models/odd';

import '../../scss/page.scss';

import Mascote from '../../assets/mascotte_larrondi_dark.png';

interface Props {
  slug: string | null;
  assoList: AssoModel[];
  Salary: SalaireModel;
  Retail: CaisseModel;
  Online: OnlineModel;
  entrepriseList: EntrepriseModel[];
}

/*
  Page Desc Module for association
*/
export const AssoPageDesc = (props: Props): JSX.Element => {
  const { assoList, slug } = props;

  const asso = assoList.find((e) => e.assoSlug === slug);
  if (asso === undefined) return <div>Name Error</div>;

  return (
    <div className="page_desc">
      <span>{asso.desc}</span>
    </div>
  );
};

/*
  Page title Module for association
*/
export const AssoPageTitle = (props: Props): JSX.Element => {
  const { assoList, slug } = props;

  const asso = assoList.find((e) => e.assoSlug === slug);
  if (asso === undefined) return <div>Name Error</div>;

  return (
    <div className="page_title">
      <div className="page_title_left">
        <div className="page_title_left_img">
          <img
            className="page_title_img"
            src={
              asso.logoUrl === undefined
                ? 'https://www.chateaudesaintmartin.com/wp-content/uploads/2020/09/placeholder-960x640.png'
                : asso.logoUrl
            }
            alt={asso.name}
          />
        </div>
        <div className="page_title_left_text">
          <p className="page_title_left_text_donationType">Association</p>
          <p className="page_title_left_text_name">{asso.name}</p>
          {asso.websiteUrl && (
            <a className="page_title_left_text_web" href={asso.websiteUrl}>
              Visiter le site Web
            </a>
          )}
        </div>
      </div>
      <div className="page_title_right">
        <img src={Mascote} alt="mascotte arrondi" height="300" />
      </div>
    </div>
  );
};

/*
  Page Content Module for association
*/
const AssoPage = (props: Props): JSX.Element => {
  const { slug, assoList, Salary, Retail, Online, entrepriseList } = props;

  if (slug === null) return <div>Name Error</div>;

  const asso = assoList.find((e) => e.assoSlug === slug);
  if (asso === undefined) return <div>Name Error</div>;

  const linkedEntreprise = getDmByAsso(
    Salary,
    Retail,
    Online,
    entrepriseList,
    asso.id,
  );

  return (
    <div>
      {asso.odd.filter((e) => e != null).length !== 0 && (
        <PageList
          title="Objectifs de Développement Durable"
          content={
            <div className="page_list_content">
              {asso.odd.map((oddId: number) => (
                <div className="asso_basic_card" key="f" data-tip={oddId}>
                  <img
                    className="asso_basic_card_img"
                    src={oddList.find((e) => e.id === oddId)?.visual}
                    alt={oddList.find((e) => e.id === oddId)?.name}
                  />
                  <ReactTooltip />
                </div>
              ))}
            </div>
          }
        />
      )}
      {linkedEntreprise.length !== 0 && (
        <PageList
          title="Entreprises partenaires actuelles"
          content={
            <div className="page_list_content">
              {linkedEntreprise.map((e: EntrepriseRelational) => (
                <EntrepriseCard {...e} key="entreprieCard" />
              ))}
            </div>
          }
        />
      )}
    </div>
  );
};

export default AssoPage;
