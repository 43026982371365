import { StylesConfig } from 'react-select';
import { DonationModels } from '../../../Models/donation.model';

// Custom type for react-select
type MyOptionType = {
  label: string;
  value: DonationModels;
  color: string;
};

// Custom style for react-select
type IsMulti = true;
const selectStyle: StylesConfig<MyOptionType, IsMulti> = {
  multiValue: (provided, { data }) => {
    return {
      ...provided,
      backgroundColor: data.color,
      borderRadius: '19px',
      color: 'white',
    };
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      color: 'white',
    };
  },
  multiValueRemove: (provided, { data }) => {
    return {
      ...provided,
      backgroundColor: data.color,
      color: 'white',
    };
  },
  container: (provided) => {
    return {
      ...provided,
      borderColor: '#001e30',
    };
  },
  control: (provided) => {
    return {
      ...provided,
      borderRadius: '19px',
      border: 'solid 1px #001e30',
      marginTop: '16px',
      boxShadow: 'none',
      '&:hover': {
        border: 'solid 1px #001e30',
      },
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#001e30',
    };
  },
  option: (provided, { data }) => {
    return {
      ...provided,
      color: data.color,
    };
  },
  indicatorSeparator: (provided) => {
    return {
      ...provided,
      visibility: 'hidden',
    };
  },
};

export default selectStyle;
