import React from 'react';
import ReactTooltip from 'react-tooltip';

import { AssoModel } from '../../Models/asso.model';

import '../../scss/asso.scss';

import Mascote from '../../assets/mascotte_larrondi_dark.png';

interface Props {
  asso: AssoModel;
}

/*
  Basic asso card displayed on multiple page (No title version)
*/
const AssoBasicCard = (props: Props): JSX.Element => {
  const { asso } = props;

  return (
    <div data-tip={asso.name} className="asso_basic_card">
      <img
        className="asso_basic_card_img"
        src={
          asso.logoUrl === undefined || asso.logoUrl === null
            ? Mascote
            : asso.logoUrl
        }
        alt=""
      />
      {!asso.logoUrl && (
        <span
          style={{
            position: 'absolute',
            left: '10px',
            top: '20px',
            color: 'black',
          }}
        >
          {asso.name}
        </span>
      )}
      <ReactTooltip />
    </div>
  );
};

export default AssoBasicCard;
