/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import GeneralModel from '../../Models/general.model';
import { AssoModel } from '../../Models/asso.model';
import ImpactKeyNbr from '../Modules/KeyNbr/impact';
import SmallAssoList from '../Modules/Lists/asso_small';
import { useWindowDimensions } from '../../utils';
import OddGraph from '../Modules/oddGraph';
import { oddList } from '../../Models/odd';

interface ImpactProp {
  general: GeneralModel;
  assoList: AssoModel[];
}

// Return Number of specific Odd found in the associations list
const getNumberOfOdd = (id: number, list: AssoModel[]) => {
  let res = 0;

  list.forEach((el) => {
    if (el.odd != null && el.odd.includes(id)) res++;
  });

  return res;
};

// Setup the data model and value for the Odd graph
const getDataFromAssoList = (list: AssoModel[]) => {
  const res: { name: string; value: any }[] = [];

  oddList.forEach((el) => {
    const value = getNumberOfOdd(el.id, list);
    if (value !== 0)
      res.push({
        name: el.name,
        value: Math.round((value / list.length) * 100),
      });
  });

  return res;
};

export const ImpactPageKeyNbr = (props: ImpactProp): JSX.Element => {
  const { general, assoList } = props;

  return (
    <div>
      {general !== undefined && (
        <ImpactKeyNbr generalData={general} assoList={assoList} />
      )}
    </div>
  );
};

export const ImpactPageAssoList = (props: ImpactProp): JSX.Element => {
  const { assoList } = props;
  const { width } = useWindowDimensions();

  return (
    <div>
      {assoList !== undefined && (
        <SmallAssoList assoList={assoList} offset={width <= 768 ? 5 : 11} />
      )}
    </div>
  );
};

const ImpactPageOddGraph = (props: ImpactProp): JSX.Element => {
  const { assoList } = props;

  return (
    <div>
      <OddGraph data={getDataFromAssoList(assoList)} />
    </div>
  );
};

export default ImpactPageOddGraph;
