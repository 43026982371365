import * as React from "react";
import { Marker } from "react-leaflet";
import { icon } from "leaflet";
import { ColectorModel } from "../../../Models/caisseCampaign.model";

interface Props {
  onMarkerClick: any;
  selected: boolean;
  position: any;
  colector: ColectorModel;
}

const markerIcon = (selected: any) =>
  icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-${
      selected ? "red" : "green"
    }.png`,
    shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
  });

export default function MarkerContainer({ position, colector, onMarkerClick, selected }: Props) {
  const eventHandlers = React.useMemo(
    () => ({
      click() {
        onMarkerClick(colector);
      },
    }),
    [onMarkerClick, colector],
  );

  return (
    <div>
      <Marker
        position={position}
        eventHandlers={eventHandlers}
        icon={markerIcon(selected)}
      />
    </div>
  );
}