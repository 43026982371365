import React from 'react';
import { AddCircle } from '@material-ui/icons';

import { checkEntrepriseValidity } from '../../../utils';

import EntrepriseCard from '../entrepriseCard';

import { EntrepriseRelational } from '../../../Models/entreprise.model';

import '../../../scss/list.scss';

interface Props {
  shuffledDmList: EntrepriseRelational[];
}

/*
  Small list with a special card to go to main list
  Maked for Home and impact page
*/
const SmallEntrepriseList = (props: Props): JSX.Element => {
  const { shuffledDmList } = props;

  // Offset is Fix
  const offset = 5;

  return (
    <div>
      <div className="list_content">
        {shuffledDmList
          .filter((e) => checkEntrepriseValidity(e.entreprise))
          .slice(0, offset)
          .map((e, idx) => {
            return idx + 1 === offset ? (
              <div className="list_see_more_block">
                <EntrepriseCard
                  entreprise={e.entreprise}
                  donationModel={e.donationModel}
                />
                <a href="/liste-entreprises/">
                  <div className="list_see_more_campaign_button">
                    <span>
                      Voir les{' '}
                      {
                        shuffledDmList.filter((el) =>
                          checkEntrepriseValidity(el.entreprise),
                        ).length
                      }{' '}
                      entreprises partenaires
                    </span>
                    <AddCircle style={{ color: '#047bc1' }} />
                  </div>
                </a>
              </div>
            ) : (
              <EntrepriseCard
                entreprise={e.entreprise}
                donationModel={e.donationModel}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SmallEntrepriseList;
