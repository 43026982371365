import * as React from 'react';
import { latLng } from 'leaflet';
import { useMap } from 'react-leaflet';
import isEqual from 'lodash.isequal';
import MarkerClusterContainer from './markerClusterContainer';
import { ColectorModel } from '../../../Models/caisseCampaign.model';
import { checkColectorValidity } from '../../../utils';

interface Props {
  onMarkerClick: any;
  selected: any[];
  colectors: ColectorModel[];
}

const generateMarkers = (colectors: ColectorModel[]) => {
  const result = colectors.filter((e) => checkColectorValidity(e)).map((colector: ColectorModel) => {
    return {
      colector,
      pos: latLng(
        +colector.coordinates[0].replace(',', '.'),
        +colector.coordinates[1].replace(',', '.'),
      ),
    };
  });
  return result;
};

const DEF_BOUNDS = latLng([51.505, -0.09]).toBounds(5000);

function MapRef({ onMarkerClick, selected, colectors }: Props) {
  const mapRef = useMap();
  const bounds = React.useRef(DEF_BOUNDS);
  const markers = generateMarkers(colectors);

  React.useEffect(() => {
    let newBounds = DEF_BOUNDS;
    if (markers?.length > 0) {
      if (selected?.length > 0) {
        const lastId = selected[selected.length - 1];
        const lastSelectedMarker = markers.find(
          (marker) => marker.colector.id === lastId,
        );
        newBounds = lastSelectedMarker
          ? latLng(lastSelectedMarker.pos).toBounds(500)
          : DEF_BOUNDS;
      }
    }

    if (!isEqual(newBounds, bounds?.current)) {
      bounds.current = newBounds;
      mapRef.fitBounds(newBounds);
    }
  }, [mapRef, selected, bounds, markers]);

  return (
    <div>
      <MarkerClusterContainer
        mapRef={mapRef}
        markers={markers}
        onMarkerClick={onMarkerClick}
        selected={selected}
      />
    </div>
  );
}

export default MapRef;
