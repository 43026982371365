/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import {
  checkColectorValidity,
  customAssoRulesRetail,
  fetchByIds,
  useWindowDimensions,
} from '../../utils';

import PageList from '../Modules/Lists/page_list';
import InteractiveMap from '../Modules/map';
import AssoBasicCard from '../Modules/asso_basic_card';

import { AssoModel } from '../../Models/asso.model';
import { EntrepriseModel } from '../../Models/entreprise.model';
import { CaisseModel } from '../../Models/caisseCampaign.model';

import Mascote from '../../assets/mascotte_larrondi_dark.png';
import NoCmp from '../../assets/no_campagne.svg';

interface Props {
  slug: string | null;
  assoList: AssoModel[];
  Retail: CaisseModel;
  entrepriseList: EntrepriseModel[];
}

/*
  Page Desc Module for retail donation client
*/
export const RetailPageDesc = (props: Props): JSX.Element => {
  const { slug, Retail, entrepriseList } = props;

  if (slug === null) return <div>Name Error</div>;

  const entreprise = entrepriseList.find((e) => e.entrepriseSlug === slug);
  const campaign = Retail.list.find((e) => e.entrepriseSlug === slug);

  if (entreprise === undefined) return <div>Entreprise Error</div>;
  if (campaign === undefined) return <div>Campaign Error</div>;

  return (
    <div className="page_desc_and_cgu">
      <span>{entreprise.desc}</span>
      <div className="page_cgu">
        Vous avez fait un don en magasin ?
        <div className="page_cgu_button">
          {campaign.urlCgu != null && (
            <a
              href={campaign.urlCgu}
              style={{
                color: '#047bc1',
                fontSize: '16px',
              }}
            >
              Consulter les CGU
            </a>
          )}
          {campaign.urlRecuFiscal && (
            <div>
              <a
                href="/recus-fiscaux/"
                style={{
                  color: '#047bc1',
                  fontSize: '16px',
                  textDecoration: 'none',
                }}
              >
                Demander un reçu fiscal
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/*
  Page Title Module for retail donation client
*/
export const RetailPageTitle = (props: Props): JSX.Element => {
  const { entrepriseList, slug } = props;

  const entreprise = entrepriseList.find((e) => e.entrepriseSlug === slug);
  if (entreprise === undefined) return <div>Entreprise Error</div>;

  return (
    <div className="page_title">
      <div className="page_title_left">
        <div className="page_title_left_img">
          <img
            className="page_title_img"
            src={
              entreprise.logoUrl === undefined
                ? 'https://www.chateaudesaintmartin.com/wp-content/uploads/2020/09/placeholder-960x640.png'
                : entreprise.logoUrl
            }
            alt={entreprise.name}
          />
        </div>
        <div className="page_title_left_text">
          <p className="page_title_left_text_donationType">
            L&lsquo;ARRONDI en caisse
          </p>
          <p className="page_title_left_text_name">{entreprise.name}</p>
        </div>
      </div>
      <div className="page_title_right">
        <img src={Mascote} alt="mascotte arrondi" height="300" />
      </div>
    </div>
  );
};

/*
  Page Content Module for retail donation client
*/
const RetailPage = (props: Props): JSX.Element => {
  const { slug, assoList, Retail, entrepriseList } = props;

  if (slug === null) return <div>Name Error</div>;

  const entreprise = entrepriseList.find((e) => e.entrepriseSlug === slug);
  const campaign = Retail.list.find((e) => e.entrepriseSlug === slug);

  if (entreprise === undefined) return <div>Entreprise Error</div>;
  if (campaign === undefined) return <div>Campaign Error</div>;

  const linkedAsso: AssoModel[] = fetchByIds(
    assoList,
    campaign.linkedCurrentBenef,
  );
  const linkedAssoOver: AssoModel[] = fetchByIds(
    assoList,
    campaign.linkedBenefOver,
  );

  return (
    <div>
      {linkedAsso.length !== 0 ? (
        <div>
          {campaign.ongoingCampaigns.length !== 0 &&
            campaign.collectorsWithOngoingCampaign.filter((e) =>
              checkColectorValidity(e),
            ).length !== 0 && (
              <div>
                <p className="campagnes_title">Campagne en cours</p>
                {entreprise.id !== '5be16c01ba64554207a999da' && (
                  <InteractiveMap
                    retailCampaign={campaign}
                    assoList={assoList}
                  />
                )}
              </div>
            )}
          <PageList
            title="Associations soutenues"
            content={
              <div className="page_list_content">
                {linkedAsso.map((asso: AssoModel) => (
                  <a href={`/asso?slug=${asso.assoSlug}`} key={asso.id}>
                    <AssoBasicCard asso={asso} />
                  </a>
                ))}
              </div>
            }
          />
        </div>
      ) : (
        <div className="no_campaign">
          <img src={NoCmp} alt="no campaign" className="no_campaign_img" />
          <p className="no_campaign_text">
            Il n&lsquo;y a pas de campagne en cours actuellement. Merci de votre
            patience
          </p>
        </div>
      )}
      <PageList
        title="Associations soutenues précédemment"
        content={
          <div className="page_list_content">
            {customAssoRulesRetail(linkedAssoOver, entreprise.id).map(
              (asso: AssoModel) => (
                <a href={`/asso?slug=${asso.assoSlug}`} key={asso.id}>
                  <AssoBasicCard asso={asso} />
                </a>
              ),
            )}
          </div>
        }
      />
    </div>
  );
};

export default RetailPage;
