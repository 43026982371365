/* eslint-disable no-shadow */
export interface DonationTypeModel {
  arrondiCaisse: boolean;
  arrondiSalaire: boolean;
  arrondieEnLigne: boolean;
  arrondiTPE: boolean;
  arrondiBancaire: boolean;
}

export interface TotalDonModel {
  nombreDon: number;
  value: number;
  since: string;
}

export enum DonationModels {
  arrondiCaisse,
  arrondiSalaire,
  arrondiEnLigne,
}
