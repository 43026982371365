import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';

import { checkEntrepriseValidity } from '../../../utils';

import EntrepriseCard from '../entrepriseCard';

import { DonationModels } from '../../../Models/donation.model';
import { EntrepriseRelational } from '../../../Models/entreprise.model';

import '../../../scss/list.scss';
import '../../../scss/asso.scss';

interface Props {
  shuffledDmList: EntrepriseRelational[];
}

const getFlagState = (flag: string) => {
  const url = new URLSearchParams(window.location.search).get(flag);
  if (url === 'true') {
    return true;
  }
  const result = sessionStorage.getItem(flag);
  return result === 'true';
};

/*
  Full List of entreprise with filter and buffer
  Used as a page to display every entreprises after filter
*/
const FullEntrepriseList = (props: Props): JSX.Element => {
  const { shuffledDmList } = props;

  // Main List holding state
  const [list] = useState<EntrepriseRelational[]>(shuffledDmList);

  // Flag state when retail is activated by filters
  const [retailFlag, setRetailFlag] = useState<boolean>(
    getFlagState('retailFilter'),
  );
  // Flag state when retail is activated by filters
  const [salaryFlag, setSalaryFlag] = useState<boolean>(
    getFlagState('salaryFilter'),
  );
  // Flag state when retail is activated by filters
  const [onlineFlag, setOnlineFlag] = useState<boolean>(
    getFlagState('onlineFilter'),
  );

  // Offset of the list, increase on user input
  const [offset, setOffset] = useState<number>(18);

  // Realtime name filter
  const [filter, setFilter] = useState<string>('');

  const onClick = () => {
    setOffset(offset + 10);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const handleRetailClick = () => {
    sessionStorage.setItem(
      'retailFilter',
      !retailFlag === true ? 'true' : 'false',
    );
    setRetailFlag(!retailFlag);
  };

  const handleSalaryClick = () => {
    sessionStorage.setItem(
      'salaryFilter',
      !salaryFlag === true ? 'true' : 'false',
    );
    setSalaryFlag(!salaryFlag);
  };

  const handleOnlineClick = () => {
    sessionStorage.setItem(
      'onlineFilter',
      !onlineFlag === true ? 'true' : 'false',
    );
    setOnlineFlag(!onlineFlag);
  };

  // Inline style for Button is forced by MUI
  return (
    <div>
      <div className="list_top_nav">
        <div className="list_filters">
          <div className="list_filters_dm">
            <button
              type="button"
              onClick={handleRetailClick}
              style={{
                backgroundColor: retailFlag ? '#047bc1' : '#ffffff',
                color: !retailFlag ? '#047bc1' : '#ffffff',
                border: '1px solid #047bc1',
                borderRadius: '16px',
                width: '173px',
                height: '34px',
              }}
              className="dm_button"
            >
              <span className="dm_button_label">L&#39;ARRONDI en caisse</span>
            </button>
            <button
              type="button"
              onClick={handleSalaryClick}
              style={{
                backgroundColor: salaryFlag ? '#047bc1' : '#ffffff',
                color: !salaryFlag ? '#047bc1' : '#ffffff',
                border: '1px solid #047bc1',
                borderRadius: '16px',
                width: '173px',
                height: '34px',
              }}
              className="dm_button"
            >
              <span className="dm_button_label">L&#39;ARRONDI sur salaire</span>
            </button>
            <button
              type="button"
              onClick={handleOnlineClick}
              style={{
                backgroundColor: onlineFlag ? '#047bc1' : '#ffffff',
                color: !onlineFlag ? '#047bc1' : '#ffffff',
                border: '1px solid #047bc1',
                borderRadius: '16px',
                width: '173px',
                height: '34px',
              }}
              className="dm_button"
            >
              <span className="dm_button_label">L&#39;ARRONDI en ligne</span>
            </button>
          </div>
          <div className="name_filter">
            <input
              value={filter}
              onChange={handleChange}
              type="text"
              style={{ border: 'none ', color: '#001e30' }}
            />
            <Icon
              style={{ paddingTop: '5px' }}
              path={mdiMagnify}
              size={1}
              horizontal
              vertical
              rotate={180}
            />
          </div>
        </div>
        <span className="entreprise_number">
          {
            list
              .filter((e) => checkEntrepriseValidity(e.entreprise))
              .filter((e) =>
                filter === ''
                  ? e
                  : e.entreprise.name
                      .toLocaleLowerCase()
                      .includes(filter.toLocaleLowerCase()),
              )
              .filter((e) =>
                !salaryFlag && (retailFlag || onlineFlag)
                  ? e.donationModel !== DonationModels.arrondiSalaire
                  : e,
              )
              .filter((e) =>
                !retailFlag && (salaryFlag || onlineFlag)
                  ? e.donationModel !== DonationModels.arrondiCaisse
                  : e,
              )
              .filter((e) =>
                !onlineFlag && (retailFlag || salaryFlag)
                  ? e.donationModel !== DonationModels.arrondiEnLigne
                  : e,
              ).length
          }
          <span> </span>entreprises
        </span>
      </div>
      <div className="list_content">
        {list
          .filter((e) => checkEntrepriseValidity(e.entreprise))
          .filter((e) =>
            filter === ''
              ? e
              : e.entreprise.name
                  .toLocaleLowerCase()
                  .includes(filter.toLocaleLowerCase()),
          )
          .filter((e) =>
            !salaryFlag && (retailFlag || onlineFlag)
              ? e.donationModel !== DonationModels.arrondiSalaire
              : e,
          )
          .filter((e) =>
            !retailFlag && (salaryFlag || onlineFlag)
              ? e.donationModel !== DonationModels.arrondiCaisse
              : e,
          )
          .filter((e) =>
            !onlineFlag && (retailFlag || salaryFlag)
              ? e.donationModel !== DonationModels.arrondiEnLigne
              : e,
          )
          .slice(0, offset)
          .map((e: EntrepriseRelational) => (
            <EntrepriseCard {...e} key="f" />
          ))}
      </div>
      <div style={{ width: '100%', display: 'flex', marginTop: '60px' }}>
        <Button
          variant="outlined"
          onClick={onClick}
          style={{
            width: '224px',
            height: '30px',
            borderRadius: '16px',
            border: 'solid 1px #047bc1',
            backgroundColor: '#fff',
            margin: '0 auto',
            textTransform: 'lowercase',
          }}
        >
          <span
            style={{ fontSize: '12px', textAlign: 'center', color: '#047BC1' }}
          >
            <span style={{ textTransform: 'uppercase' }}>C</span>harger plus de
            résulat
          </span>
        </Button>
      </div>
    </div>
  );
};

export default FullEntrepriseList;
