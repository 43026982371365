/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { fetchByIds } from '../../utils';

import PageList from '../Modules/Lists/page_list';
import AssoBasicCard from '../Modules/asso_basic_card';

import { SalaireModel } from '../../Models/salaireCampaign.model';
import { AssoModel } from '../../Models/asso.model';
import { EntrepriseModel } from '../../Models/entreprise.model';

import Mascote from '../../assets/mascotte_larrondi_dark.png';

interface Props {
  slug: string | null;
  assoList: AssoModel[];
  Salary: SalaireModel;
  entrepriseList: EntrepriseModel[];
}

/*
  Page Desc Module for salary donation client
*/
export const SalaryPageDesc = (props: Props): JSX.Element => {
  const { entrepriseList, slug } = props;

  const entreprise = entrepriseList.find((e) => e.entrepriseSlug === slug);
  if (entreprise === undefined) return <div>Entreprise Error</div>;

  return (
    <div className="page_desc">
      <span>{entreprise.desc}</span>
    </div>
  );
};

/*
  Page Title Module for salary donation client
*/
export const SalaryPageTitle = (props: Props): JSX.Element => {
  const { entrepriseList, slug } = props;

  const entreprise = entrepriseList.find((e) => e.entrepriseSlug === slug);
  if (entreprise === undefined) return <div>Entreprise Error</div>;

  return (
    <div className="page_title">
      <div className="page_title_left">
        <div className="page_title_left_img">
          <img
            className="page_title_img"
            src={
              entreprise.logoUrl === undefined
                ? 'https://www.chateaudesaintmartin.com/wp-content/uploads/2020/09/placeholder-960x640.png'
                : entreprise.logoUrl
            }
            alt={entreprise.name}
          />
        </div>
        <div className="page_title_left_text">
          <p className="page_title_left_text_donationType">
            L&lsquo;ARRONDI sur salaire
          </p>
          <p className="page_title_left_text_name">{entreprise.name}</p>
        </div>
      </div>
      <div className="page_title_right">
        <img src={Mascote} alt="mascotte arrondi" height="300" />
      </div>
    </div>
  );
};

/*
  Page Content Module for salary donation client
*/
const SalaryPage = (props: Props): JSX.Element => {
  const { slug, assoList, Salary, entrepriseList } = props;

  if (slug === null) return <div>Name Error</div>;

  const entreprise = entrepriseList.find((e) => e.entrepriseSlug === slug);
  const campaign = Salary.list.find((e) => e.entrepriseSlug === slug);

  if (entreprise === undefined) return <div>Entreprise Error</div>;
  if (campaign === undefined) return <div>Campaign Error</div>;

  const linkedAsso: AssoModel[] = fetchByIds(
    assoList,
    campaign.linkedCurrentBenef,
  );

  return (
    <div>
      <PageList
        title="Associations soutenues"
        content={
          <div className="page_list_content">
            {linkedAsso.map((asso: AssoModel) => (
              <a href={`/asso?slug=${asso.assoSlug}`} key={asso.id}>
                <AssoBasicCard asso={asso} />
              </a>
            ))}
          </div>
        }
      />
    </div>
  );
};

export default SalaryPage;
