/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';
import shuffle from 'lodash.shuffle';
import { AssoModel } from './Models/asso.model';
import {
  CaisseCampaignModel,
  CaisseModel,
  ColectorModel,
} from './Models/caisseCampaign.model';
import { DonationModels } from './Models/donation.model';
import {
  EntrepriseModel,
  EntrepriseRelational,
} from './Models/entreprise.model';
import Odd from './Models/odd';
import {
  OnlineCampaignModel,
  OnlineModel,
} from './Models/onlineCampaign.model';
import {
  SalaireCampaignModel,
  SalaireModel,
} from './Models/salaireCampaign.model';

/* eslint-disable @typescript-eslint/no-explicit-any */

// Return item from src where src.id == id
const fetchById = (src: any[], id: string) => {
  return src.find((el: any) => el.id === id);
};

// Return item from src where src includes ids
export const fetchByIds = (src: any[], ids: string[]) => {
  // console.log(ids);
  if (ids === undefined || ids.length === 0) return [];
  return src.filter((el: any) => ids.includes(el.id));
};

// convert Odd to react-select option format
export const stringToSelectOption = (src: Odd[]) => {
  const res: any[] = [];

  src.forEach((e) => {
    res.push({ value: e.name, label: e.name, color: e.color, id: e.id});
  });
  return res;
};

// convert react-select option format to Odd
export const SelectOptionTostring = (src: any[]) => {
  const res: string[] = [];

  src.forEach((e) => {
    res.push(e.value);
  });
  return res;
};

// Check if src includes oddList
export const checkOdd = (src: number[], oddList: number[]) => {
  let res = false;
  if (src == null) return res;
  oddList.forEach((e: number) => {
    if (src.includes(e)) res = true;
  });
  return res;
};

// Return all src member that contain oddList member
export const fetchByOdd = (src: any[], oddList: number[]) => {
  const res: any[] = [];

  if (oddList.length === 0) return src;
  src.forEach((e) => {
    e.odd.forEach((el: any) => {
      if (oddList.includes(el)) res.push(e);
    });
  });
  return res;
};

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

// Main asso filter; Filter out if logo or desc missing
export const checkAssoValidity = (asso: AssoModel) => {
  if (
    asso.logoUrl === undefined ||
    asso.logoUrl === '' ||
    asso.logoUrl === null
  )
    return false;
  if (asso.name === undefined || asso.name === '') return false;
  return true;
};

// Main Entreprise filter; Filter out if logo or desc missing
export const checkEntrepriseValidity = (entreprise: EntrepriseModel) => {
  if (entreprise === undefined) return false;
  if (
    entreprise.logoUrl === undefined ||
    entreprise.logoUrl === '' ||
    entreprise.logoUrl === null
  )
    return false;
  if (entreprise.name === undefined || entreprise.name === '') return false;
  return true;
};

// Main Collector filter; Filter out if cooridnates are null
export const checkColectorValidity = (colector: ColectorModel) => {
  if (colector.coordinates[0] === null || colector.coordinates[1] === null)
    return false;
  return true;
};


export const customAssoRulesRetail = (assos: AssoModel[], entrepriseId: string) => {
  if (entrepriseId === "60d2f58d8b9c38001e13c421") {
    return assos.filter((e) => e.id !== "583346bab473c8f835a5d181");
  }
  return assos;
}

export const customAssoRules = (assos: AssoModel[]) => {
  return assos.filter((e) => e.id !== "5ce5391f9c7206000671dbc3");
}


// Create and shuffle and array that hold list from the  donations model
export const getShuffledDmArray = (
  salary: SalaireModel,
  retail: CaisseModel,
  online: OnlineModel,
  entrepriseList: EntrepriseModel[],
) => {
  const res: EntrepriseRelational[] = [];

  salary.list.forEach((e: SalaireCampaignModel) => {
    res.push({
      entreprise: fetchById(entrepriseList, e.entrepriseId),
      donationModel: DonationModels.arrondiSalaire,
    });
  });
  retail.list.forEach((e: CaisseCampaignModel) => {
    res.push({
      entreprise: fetchById(entrepriseList, e.entrepriseId),
      donationModel: DonationModels.arrondiCaisse,
    });
  });
  online.list.forEach((e: OnlineCampaignModel) => {
    res.push({
      entreprise: fetchById(entrepriseList, e.entrepriseId),
      donationModel: DonationModels.arrondiEnLigne,
    });
  });
  return shuffle(res.filter((e) => e.entreprise !== undefined));
};

// Return a custom and shuffled list of asso based on Donation Model
export const getDmByAsso = (
  salary: SalaireModel,
  retail: CaisseModel,
  online: OnlineModel,
  entrepriseList: EntrepriseModel[],
  id: string,
) => {
  const res: EntrepriseRelational[] = [];

  salary.list.forEach((e: SalaireCampaignModel) => {
    if (e.linkedCurrentBenef.includes(id))
      res.push({
        entreprise: fetchById(entrepriseList, e.entrepriseId),
        donationModel: DonationModels.arrondiSalaire,
      });
  });
  retail.list.forEach((e: CaisseCampaignModel) => {
    if (e.linkedCurrentBenef.includes(id))
      res.push({
        entreprise: fetchById(entrepriseList, e.entrepriseId),
        donationModel: DonationModels.arrondiCaisse,
      });
  });
  online.list.forEach((e: OnlineCampaignModel) => {
    if (e.linkedCurrentBenef.includes(id))
      res.push({
        entreprise: fetchById(entrepriseList, e.entrepriseId),
        donationModel: DonationModels.arrondiEnLigne,
      });
  });
  return shuffle(res);
};

export default fetchById;
