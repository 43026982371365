import React, { ReactElement } from 'react';

import '../../../scss/page.scss';

interface Props {
  title: string;
  content: ReactElement;
}

/* 
  Small component for focus page
  Display a title followed by content
*/
const PageList = (props: Props): JSX.Element => {
  const { title, content } = props;

  return (
    <div className="page_list">
      <h2 className="page_list_title">{title}</h2>
      <div>{content}</div>
    </div>
  );
};

export default PageList;
