import React from 'react';
import ReactTooltip from 'react-tooltip';

import '../../scss/graph.scss';

interface Props {
  data: { name: string; value: number }[];
}

/*
  Custom graph that display all ODD with max value based on ODD (sorted >)
*/
const OddGraph = (props: Props): JSX.Element => {
  const { data } = props;

  const getMaxValue = () => {
    const result: number[] = [];
    data.forEach((e) => {
      result.push(e.value);
    });
    return Math.max(...result);
  };

  const maxValue = getMaxValue();
  return (
    <div className="graph">
      {data
        .sort((a, b) => {
          return a.value > b.value ? -1 : 1;
        })
        .filter((e) => e.value !== 0)
        .map((odd) => {
          return (
            <div className="graph_bar" key={odd.value}>
              <span data-tip={odd.name} className="graph_bar_label">
                {odd.name}
              </span>
              <ReactTooltip />
              <div className="graph_bar_box">
                <div
                  className="graph_bar_fill"
                  style={{ width: `${(100 * odd.value) / maxValue}%` }}
                >
                  <span className="graph_bar_value">{odd.value} %</span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default OddGraph;
