/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import { MetricCardNoIconBiColor } from '../metrics_card';

import GeneralModel from '../../../Models/general.model';
import { AssoModel } from '../../../Models/asso.model';

import '../../../scss/keyNbr.scss';

import Mascote from '../../../assets/mascotte_larrondi_blue.png';

interface Props {
  generalData: GeneralModel;
  assoList: AssoModel[];
}

/*
  Display Key numbers for the impact page in a row
  Very similar to HomeKeyNbr but with color swift
*/
const ImpactKeyNbr = (props: Props): JSX.Element => {
  const { generalData } = props;

  return (
    <div>
      <div className="basic_keynbr_list">
        <div className="basic_keynbr_list_elem">
          <img
            src={Mascote}
            alt="mascotte arrondi"
            width="47"
            height="40"
            color="#001e30"
          />
          <span id="elem_subtitle" style={{ color: '#001e30' }}>
            Depuis {generalData.totalDons.since}
          </span>
        </div>
        <MetricCardNoIconBiColor
          title={`${generalData.totalDons.value.toLocaleString('fr')} €`}
          subtitle="collectés pour des associations"
          textColor="#001e30"
          subColor="#001e30"
        />
        <MetricCardNoIconBiColor
          title={`${generalData.totalDons.nombreDon.toLocaleString('fr')}`}
          subtitle="dons effectués via L’ARRONDI"
          textColor="#001e30"
          subColor="#001e30"
        />
        <MetricCardNoIconBiColor
          title={`${generalData.totalBeneficiaries.toLocaleString('fr')}`}
          subtitle="associations bénéficiaires"
          textColor="#001e30"
          subColor="#001e30"
        />
      </div>
    </div>
  );
};

export default ImpactKeyNbr;
