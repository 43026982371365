/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import { useWindowDimensions } from '../../utils';

import HomeKeyNbr from '../Modules/KeyNbr/home';
import SmallAssoList from '../Modules/Lists/asso_small';

import { AssoModel } from '../../Models/asso.model';
import GeneralModel from '../../Models/general.model';

interface HomeProp {
  general: GeneralModel;
  assoList: AssoModel[];
}

/*
  Home Key Numbers holder
*/
const HomePageKeyNbr = (props: HomeProp): JSX.Element => {
  const { general, assoList } = props;

  return (
    <div>
      {general !== undefined && (
        <HomeKeyNbr generalData={general} assoList={assoList} />
      )}
    </div>
  );
};

/*
  Home Asso List holder
*/
export const HomePageAssoList = (props: HomeProp): JSX.Element => {
  const { assoList } = props;
  const { width } = useWindowDimensions();

  return (
    <div>
      {assoList !== undefined && (
        <SmallAssoList assoList={assoList} offset={width <= 768 ? 5 : 11} />
      )}
    </div>
  );
};

export default HomePageKeyNbr;
