import v2 from '../assets/F-WEB-Goal-02.png';
import v3 from '../assets/F-WEB-Goal-03.png';
import v4 from '../assets/F-WEB-Goal-04.png';
import v5 from '../assets/F-WEB-Goal-05.png';
import v6 from '../assets/F-WEB-Goal-06.png';
import v7 from '../assets/F-WEB-Goal-07.png';
import v8 from '../assets/F-WEB-Goal-08.png';
import v9 from '../assets/F-WEB-Goal-09.png';
import v10 from '../assets/F-WEB-Goal-10.png';
import v11 from '../assets/F-WEB-Goal-11.png';
import v12 from '../assets/F-WEB-Goal-12.png';
import v13 from '../assets/F-WEB-Goal-13.png';
import v14 from '../assets/F-WEB-Goal-14.png';
import v15 from '../assets/F-WEB-Goal-15.png';
import v16 from '../assets/F-WEB-Goal-16.png';
import v17 from '../assets/F-WEB-Goal-17.png';
import v1 from '../assets/F-WEB-Goal-01.png';

export default interface Odd {
  id: number;
  name: string;
  visual: string;
  color: string;
}

export const oddList: Odd[] = [
  {
    id: 1,
    name: 'Pas de pauvreté',
    visual: v1,
    color: '#ea1c2d',
  },
  {
    id: 2,
    name: 'Faim "zéro"',
    visual: v2,
    color: '#d3a029',
  },
  {
    id: 3,
    name: 'Bonne santé et bien-être',
    visual: v3,
    color: '#279b48',
  },
  {
    id: 4,
    name: 'Education de qualité',
    visual: v4,
    color: '#c31f33',
  },
  {
    id: 5,
    name: 'Egalité entre les sexes',
    visual: v5,
    color: '#ef402b',
  },
  {
    id: 6,
    name: 'Eau propre et assainissement',
    visual: v6,
    color: '#00aed9',
  },
  {
    id: 7,
    name: "Energie propre et d'un coût abordable",
    visual: v7,
    color: '#fdb713',
  },
  {
    id: 8,
    name: 'Travail décent et croissance économique',
    visual: v8,
    color: '#8e1737',
  },
  {
    id: 9,
    name: 'Industrie, innovation et infrastructure',
    visual: v9,
    color: '#f36d25',
  },
  {
    id: 10,
    name: 'Inégalités réduites',
    visual: v10,
    color: '#e11484',
  },
  {
    id: 11,
    name: 'Villes et communautés durables',
    visual: v11,
    color: '#f99d26',
  },
  {
    id: 12,
    name: 'Consommation et production responsable',
    visual: v12,
    color: '#cf8d2a',
  },
  {
    id: 13,
    name: 'Mesures relatives à la lutte contre les changements climatiques',
    visual: v13,
    color: '#48773e',
  },
  {
    id: 14,
    name: 'Vie aquatique',
    visual: v14,
    color: '#007dbc',
  },
  {
    id: 15,
    name: 'Vie terrestre',
    visual: v15,
    color: '#3daf48',
  },
  {
    id: 16,
    name: 'Paix, justice et institutions efficaces',
    visual: v16,
    color: '#02558b',
  },
  {
    id: 17,
    name: 'Partenariats pour la réalisation des objectifs',
    visual: v17,
    color: '#183667',
  },
];
